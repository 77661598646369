.hdaLoadingSpinner {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
}

.hdaLoadingSpinner-label {
    margin-right: 0.75em;
}