.disclaimer .accordion .accordionDetails > div > ol > li > ol {
  padding-top: 1rem;
  padding-bottom: 1rem;
  list-style-type: lower-alpha;
}

.disclaimer .accordion ol > li > ol > li > ol {
  padding-top: 0;
  list-style-type: lower-roman;
}

.disclaimer {
  color: rgba(0, 0, 0, 0.87);
}
