.hda-esri-navigate,
.hda-esri-navigate:disabled {
    border: 1px solid var(--mui-palette-grey-400) !important;
    background: transparent !important;
    transition: opacity 0.3s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0s;
    height: 100%;


    img {
        transition: opacity 0.3s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-delay: 0s;
        opacity: 0.5;
        width: 28px;
        object-fit: contain;
    }
}

.hda-esri-navigate:disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}

.hda-esri-navigate:hover:not(:disabled) {
    border: 1px solid rgba(0, 0, 0, 0.87) !important;
    opacity: 1;

    img {
        opacity: 1;
    }
}

.hda-esri-navigate-dialog .dialog-content {
    color: rgba(0, 0, 0, 0.87);
}